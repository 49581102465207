<template>
  <v-container id="dashboard" fluid tag="section">
    <v-form ref="product">
      <base-material-card icon="mdi-tshirt-crew">
        <slot slot="title">{{product.name}}</slot>
        <slot slot="rightButtons" v-if="product.id">
          <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
        </slot>
        <div v-if="showEditToggle">

          <text-field label="Name" v-model="editProduct.name" :rules="[utils.requiredRule]">
            <slot slot="tooltip">The name of the product - examples include "T-Shirt", or "Baseball Cap"</slot> 
          </text-field>
          <text-field label="Description" v-model="editProduct.description" >
            <slot slot="tooltip">Extra description for this product, for example, "Also comes in pink."</slot> 
          </text-field>
          <text-field label="Quantity" v-model="editProduct.quantity" :rules="[utils.integerRule]">
            <slot slot="tooltip">The total quantity of product that is avaiable.<br/>You can change this at any time, but it can't be less than the number products already sold.</slot> 
          </text-field>

          <text-field label="Max per Order" v-model="editProduct.maximumPerOrder" :rules="[utils.integerRule]">
            <slot slot="tooltip">The maximum number of this product each customer can buy at one time.</slot> 
          </text-field>

        <v-select v-model="editProduct.currency" :items='currencies'>
          <slot slot="tooltip">The currency your fans will pay for this ticket type.</slot>
        </v-select>

          <text-field label="Price" :prefix="getCurrencyPrefix(editProduct.currency)" v-model="editProduct.price" :rules="[priceRule]">
            <slot slot="tooltip">The headline price for your product.  <br/>You must have connected your Box Office to Stripe to sell stuff. To do this, visit Openstage Manager > Settings > Artist Settings and click on the Payment tabs.</slot> 
          </text-field>

        <text-field label="Priority" v-model="editProduct.priority" :rules="[utils.integerRule]">
          <slot slot="tooltip">Products are listed in priority order,<br/>Priority "1" indicates that the product will be shown first.</slot> 
        </text-field>

          <text-field label="Product Code" v-model="editProduct.productCode">
            <slot slot="tooltip">
              Optional. For selling merchandise where your supplier needs a code.
            </slot>           
          </text-field>

          <text-field label="Options" v-model="editProduct.options">
            <slot slot="tooltip">
              Optional. If your product comes in different sizes or colours, enter them here.<br/>Seperate each option with a , (comma).  e.g. S,M,L,XL
            </slot>           
          </text-field>

          <div class="d-flex">
            <v-switch v-model="editProduct.collectAddress"></v-switch>
            <div class="pt-5" style="valign:middle">Collect buyer's address? 
            <tooltip left>If you are shipping this product, then it is best to collect the customer's address at the checkout</tooltip></div>
          </div>

          <div class="d-flex">
            <v-switch v-model="editProduct.show"></v-switch>
            <div class="pt-5" style="valign:middle">Show product at box office? 
            <tooltip left>Would you would like to show or hide this product?</tooltip></div>
          </div>

          <div class="d-flex">
            <v-switch v-model="editProduct.showAvailability"></v-switch>
            <div class="pt-5" style="valign:middle">Show availability at box office? 
            <tooltip left>Would you would like to show customers the number of products still available when they are purchasing at the Box Office?</tooltip></div>
          </div>

          <div :class="{'d-flex': $vuetify.breakpoint.smAndUp}">
            On sale
            <div class="d-flex">
              <div small class="ml-2 mr-2"> from</div> 
              <datetime input-id="start" class="mb-5" input-style="border-bottom:1px solid" v-model="onSaleFrom" type="datetime" :max-datetime="onSaleTo"></datetime>
            </div> 
            <div class="d-flex">
              <span small class="ml-2 mr-2"> to </span>
              <datetime input-id="end" class="mb-5" input-style="border-bottom:1px solid" v-model="onSaleTo" type="datetime" :min-datetime="onSaleFrom"></datetime>
            </div> 
            <div class="ml-1" style="valign:top"><tooltip left>When should this product go on sale, and stop being sold?<br/>Leave blank to start selling tickets immediately and indefinitely.</tooltip></div>
          </div>

          <v-label>
            <div class="mt-3 mb-1">Product Image
              <tooltip top>
                Customers can see this when they buy products.
              </tooltip>
            </div>
          </v-label>
          <img v-if="image" :src="getCloudinaryImage({url: image, width: 110})">
          <select-image-button v-if="images" :images="images" :boxOffice="boxOffice" @selectImage="selectImage"/>

            <v-row class="mt-5">
              <v-col cols="auto">
                <v-btn color="success" @click="updateProduct">Save</v-btn>
                <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
              </v-col>
              <v-spacer/>
              <v-col cols="auto">
                <v-btn class="mr-0" color="error" @click="deleteProduct">Delete</v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="4">Name</v-col>
              <v-col cols="8">{{product.name}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Description</v-col>
              <v-col cols="8">{{product.description}}</v-col>
            </v-row>          
            <v-row>
              <v-col cols="4">Quantity</v-col>
              <v-col cols="8">{{product.quantity}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Max Products per Order</v-col>
              <v-col cols="8">{{product.maximumPerOrder}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Price</v-col>
              <v-col cols="8">{{utils.priceDisplay(product.price, product.currency)}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Priority</v-col>
              <v-col cols="8">{{product.priority}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Product Code</v-col>
              <v-col cols="8">{{product.productCode}}</v-col>
            </v-row>          
            <v-row>
              <v-col cols="4">Options</v-col>
              <v-col cols="8">{{product.options}}</v-col>
            </v-row>          
            <v-row>
              <v-col cols="4">Show Product?</v-col>
              <v-col cols="8">{{product.show}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Show Availability?</v-col>
              <v-col cols="8">{{product.showAvailability}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Collect Address?</v-col>
              <v-col cols="8">{{product.collectAddress}}</v-col>
            </v-row>
            <v-row v-if="product.onSaleFrom || product.onSaleTo">
              <v-col cols="4">On sale</v-col>
              <v-col cols="8">from {{utils.dateToShortString(product.onSaleFrom)}} to {{utils.dateToShortString(product.onSaleTo)}}</v-col>
            </v-row>
            <v-row v-if="product.image">
              <v-col cols="4">Product Image</v-col>
              <v-col cols="8">
                <img :src="getCloudinaryImage({url: product.image, width: 110})">
              </v-col>
            </v-row>
          </div>
      </base-material-card>
    </v-form>

    <v-form ref="orderItem">
      <base-material-card icon="mdi-cart-check">
        <slot slot="title">Order Items</slot>
        <v-row class="mx-n1 underlinedRow">
          <v-col cols="3">Order Number</v-col>
          <v-col cols="3">Name</v-col>
          <v-col cols="3">Quantity</v-col>
          <v-col cols="3">Value</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(orderItem, i) in orderItems" :key="i" @click="selectItem(orderItem)">
              <v-col cols="3">{{orderItem.orderNumber}}</v-col>
              <v-col cols="3">{{orderItem.orderName}}</v-col>
              <v-col cols="3">{{orderItem.quantity}}</v-col>
              <v-col cols="3">{{utils.priceDisplay(orderItem.value, product.currency)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </base-material-card>
    </v-form>

  </v-container>
</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js';
import Store from '@/services/Store.js';
import { getCloudinaryImage } from '@/helpers/cloudinary'
import currencies, { getCurrencyPrefix } from '@/helpers/currency'

  export default {
    name: 'Product',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
      SelectImageButton: () => import('./components/SelectImageButton'),
    },  

    data () {
      return {
        currencies,
        getCurrencyPrefix,
        getCloudinaryImage,
        product: {},
        boxOffice: {},
        editProduct: {},
        orderItems: [],
        user: {},
        onSaleFrom: null,
        onSaleTo: null,
        image: null,
        images: null,
        showEditToggle: false,
      }
    },

    computed: {
      utils: function () {
        return Utils;
      },
    },

    created() {
      this.product.id = this.$route.params.id;
      this.load();
    },
    
    methods: {

      selectImage(image) {
        this.image = image;
        this.editProduct.image = image;
      },

      priceRule(value) {
        return Utils.priceRuleAndCheckStripe(this.boxOffice, value);
      },

      async load() {
        this.product = await Api.post(this, "Product", "read", this.product);
        this.boxOffice.id = this.product.boxOfficeId;
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice);
        this.listOrderItems();
        this.images = await Api.post(this, "Image", "list", this.boxOffice)
      },

      async listOrderItems() {
        this.orderItems = await Api.post(this, "OrderItem", "listByProduct", this.product);
      },

      async updateProduct() {
        if (! await Utils.validate(this.$refs.product) ) {
          return;
        }
        this.product = this.editProduct;
        this.product.price = Utils.priceToNumber(this.editProduct.price);
        this.product.onSaleFrom = Utils.dateToDateTime(this.onSaleFrom);
        this.product.onSaleTo = Utils.dateToDateTime(this.onSaleTo);
        this.product = await Api.post(this, "Product", "update", this.product);
        this.showEdit(false);
      },

      showEdit(show) {
        if (show) {
          this.editProduct = Utils.jsonCopy(this.product);
          this.editProduct.price = Utils.priceToString(this.product.price);
          this.onSaleFrom = Utils.dateToIso(this.product.onSaleFrom);
          this.onSaleTo = Utils.dateToIso(this.product.onSaleTo);
          this.image = this.product.image;
        } 
        this.showEditToggle = show;
      },

      selectItem(orderItem) {
        this.$router.push("/Dashboard/Order/" + orderItem.orderId)
      },

      async deleteProduct() {
        await Api.post(this, "Product", "delete", this.product);
        this.$router.push("/Dashboard/Products/" + this.product.boxOfficeId)
      },
    }
  }
</script>
